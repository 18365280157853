<template>
  <div class="app-container">
    <h2 class="message-title">消息列表</h2>
    <eHeader ref="header" @clear="clearHandle" :query="query"/>
    <!--表格渲染-->
    <el-table
      v-loading="loading"
      :data="data"
      size="small"
      border
      stripe
      style="width: 100%;"
    >
      <el-table-column prop="id" label="ID"/>
      <el-table-column prop="siteName" label="所属社区"/>
      <el-table-column label="客户信息">
        <template slot-scope="scope">
          <span>{{scope.row.userName}}</span>
          <span>{{scope.row.email}}</span>
          <span>{{scope.row.phone}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="noticeTitle" label="消息类型"/>
      <el-table-column label="消息内容">
        <template slot-scope="scope">
          <span>{{scope.row.noticeContent}}</span>
        </template>
      </el-table-column>
      <el-table-column label="站内信">
        <template slot-scope="scope">
          <div class="message-type">{{getSiteMessage(scope.row.channel)}}</div>
        </template>
      </el-table-column>
      <el-table-column label="邮件">
        <template slot-scope="scope">
          <div class="message-type">{{getEmailMessage(scope.row.channel)}}</div>
        </template>
      </el-table-column>
      <el-table-column label="短信">
        <template slot-scope="scope">
          <div class="message-type">{{getPhoneMessage(scope.row.channel)}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="noticeContent" label="发送时间">
        <template slot-scope="scope">
          <span>{{getTime(scope.row.createTime)}}</span>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
  </div>
</template>

<script>
  import checkPermission from '@/utils/permission'
  import initData from '@/mixins/initData'
  // import { del } from '@/api/srCommunityNotice'
  import { parseTime } from '@/utils/index'
  import eHeader from '@/components/system/message/notice/header'
  export default {
    components: { eHeader },
    mixins: [initData],
    data() {
      return {
        delLoading: false, sup_this: this
      }
    },
    created() {
      this.$nextTick(() => {
        this.init()
      })
    },
    methods: {
      parseTime,
      checkPermission,
      beforeInit() {
        this.url = 'community/crm/queryCommunityNoticeList'
        const sort = 'id,desc'
        this.params = {
          page: this.page,
          size: this.size,
          sort: sort,
          queryName: this.query.queryName,
          channel: this.query.channel,
        }
        if (this.$refs.header.$refs.searchcommunity.siteId) {
            this.params['siteId'] = this.$refs.header.$refs.searchcommunity.siteId
        }
        const startTime = this.parseTime(this.query.startTime);
        const endTime = this.parseTime(this.query.endTime);
        console.log(startTime)
        if(startTime){
          this.params.startTime = startTime;
        }
        if(endTime){
          this.params.endTime = endTime;
        }
        return true
      },
      subDelete(id) {
        this.delLoading = true
        del(id).then(res => {
          this.delLoading = false
          this.$refs[id].doClose()
          this.init()
          this.$notify({
            title: '删除成功',
            type: 'success',
            duration: 2500
          })
        }).catch(err => {
          this.delLoading = false
          this.$refs[id].doClose()
          console.log(err.response.data.message)
        })
      },
      getSiteMessage(channel){
        return channel.indexOf('1') > -1 ? '√' : '';
      },
      getEmailMessage(channel){
        return channel.indexOf('2') > -1 ? '√' : '';
      },
      getPhoneMessage(channel){
        return channel.indexOf('3') > -1 ? '√' : '';
      },
      getTime(time){
        return this.parseTime(time);
      },
      clearHandle(){
        this.query.siteId = '';
        this.query.queryName = '';
        this.query.channel = '';
        this.query.startTime = '';
        this.query.endTime = '';
        this.init();
      }
    }
  }
</script>

<style scoped>
.message-title {
  font-size: 30px;
  font-weight: 700;
}
  .message-type {
    text-align: center;
  }
</style>
