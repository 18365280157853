<template>
  <div class="head-container">
    <!-- 搜索 -->
    <search-community ref="searchcommunity"></search-community>
    <el-input v-model="query.queryName" clearable placeholder="输入客户昵称、电话或邮箱" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-select v-model="query.channel" clearable placeholder="发送渠道" class="filter-item" style="width: 130px">
      <el-option v-for="item in queryTypeOptions" :key="item.key" :label="item.display_name" :value="item.key"/>
    </el-select>
    <div class="filter-item">
      <el-date-picker
        style="width: 150px;"
        v-model="query.startTime"
        type="date"
        placeholder="开始日期">
      </el-date-picker>
      -
      <el-date-picker
        style="width: 150px;"
        v-model="query.endTime"
        type="date"
        placeholder="结束日期">
      </el-date-picker>
    </div>
    <el-button class="filter-item" size="mini" @click="clearHandle">清空</el-button>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <!-- 新增 -->
<!--    <div style="display: inline-block;margin: 0px 2px;">-->
<!--      <el-button-->
<!--        v-if="checkPermission(['ADMIN'])"-->
<!--        class="filter-item"-->
<!--        size="mini"-->
<!--        type="primary"-->
<!--        icon="el-icon-plus"-->
<!--        @click="$refs.form.dialog = true">新增</el-button>-->
<!--      <eForm ref="form" :is-add="true"/>-->
<!--    </div>-->
  </div>
</template>

<script>
  import searchCommunity from '@/components/common/searchCommunity'
import checkPermission from '@/utils/permission' // 权限判断函数
export default {
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  components: { searchCommunity },
  data() {
    return {
      queryTypeOptions: [
        { key: 1, display_name: '站内信' },
        { key: 2, display_name: '邮件' },
        { key: 3, display_name: '短信' },
      ],
      currentPage: 0,
      size: 1000,
      status: 4,
    }
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$parent.page = 0
      this.$parent.init()
    },
    clearHandle(){
      this.$emit('clear')
    }
  }
}
</script>
